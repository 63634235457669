$(window).on('load resize', function () {
	var windowsWidth = $(window).width();

	if (windowsWidth <= 767) {
		// console.log('mobile');
		$('.btn-navbar-toggle').sideNav({
			menuWidth: 290, // Default is 240
			//edge: 'right', // Choose the horizontal origin
			closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
			draggable: true, // Choose whether you can drag to open on touch screens
		});
		$('.mobile-filter-menu').removeAttr('style');
	} else {
		// console.log('desktop');
	}
});
// var stickySidebar = $.fn.stickySidebar.noConflict(); // Returns $.fn.stickySidebar assigned value.
// $.fn.stickySidebar = stickySidebar; // Give $().stickySidebar
$(document).ready(function () {
	// MObile menu
	var animationSpeed = 250,
		subMenuSelector = '.sub-menu';

	$('.sidebar-navigation').on('click', 'li a', function (e) {
		var $this = $(this);
		var checkElement = $this.next();
		if (checkElement.is(subMenuSelector) && checkElement.is(':visible')) {
			checkElement.slideUp(animationSpeed, function () {
				checkElement.removeClass('open');
			});
			checkElement.parent('li').removeClass('active');
		}
		//If the menu is not visible
		else if (checkElement.is(subMenuSelector) && !checkElement.is(':visible')) {
			//Get the parent menu
			var parent = $this.parents('ul').first();
			//Close all open menus within the parent
			var ul = parent.find('ul:visible').slideUp(animationSpeed);
			//Remove the open class from the parent
			ul.removeClass('open');
			//Get the parent li
			var parent_li = $this.parent('li');

			//Open the target menu and add the open class
			checkElement.slideDown(animationSpeed, function () {
				//Add the class active to the parent li
				checkElement.addClass('open');
				parent.find('li.active').removeClass('active');
				parent_li.addClass('active');
			});
		}
		//if this isn't a link, prevent the page from being redirected
		if (checkElement.is(subMenuSelector)) {
			e.preventDefault();
		}
	});

	// $.duplicate();
	//
	$.icodetutsfrminput.init();
	$('[data-toggle="popover"]').popover();

	$('.resize-textarea').trigger('autoresize');
	// set bootstrap-select version

	$('.btn-sidenav').sideNav({
		menuWidth: 290, // Default is 240
		//edge: 'right', // Choose the horizontal origin
		closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
		draggable: true, // Choose whether you can drag to open on touch screens
	});

	// $('.travellers-close').sideNav('hide');
	$('.travellers-close').sideNav('hide');
	$('body').prepend(
		"<div id='move-top' class='btn btn-theme hoverable move-top'><i class='fa fa-arrow-up'></i><span class='backtxt'>Back to Top</span></div>"
	);
	var scrollTopBtn = 'html,body';
	/*Opera does a strange thing if we use 'html' and 'body' together*/
	if (navigator.userAgent.match(/opera/i)) {
		scrollTopBtn = 'html';
	}
	// show ,hide move top button button
	$('#move-top').hide();
	jQuery(window).on('scroll', function () {
		if ($(this).scrollTop() > 180) {
			$('#move-top').fadeIn();
		} else {
			$('#move-top').fadeOut();
		}
	});
	// scroll to top when click
	jQuery('#move-top').on('click', function (e) {
		jQuery(scrollTopBtn).animate(
			{
				scrollTop: 0,
			},
			{
				duration: 600,
			}
		);
		e.preventDefault();
	});

	// auto close modal

	var modalUniqueClass = '.modalLoop';
	$('.modalLoop').on('show.bs.modal', function (e) {
		var $element = $(this);
		var $uniques = $(modalUniqueClass + ':visible').not($(this));
		if ($uniques.length) {
			$uniques.modal('hide');
			$uniques.one('hidden.bs.modal', function (e) {
				$element.modal('show');
			});
			return false;
		}
	});
	$('.modalLoop').on('shown.bs.modal', function () {
		$(this).find('[autofocus]').focus();
	});

	// File Input Path

	$(document).on('change', '.form-file-group input[type="file"]', function () {
		var fileField = $(this).closest('.form-file-group');
		var fileinputpath = fileField.find('input.file-path');
		var files = $(this)[0].files;
		var filenames = [];
		for (var i = 0; i < files.length; i++) {
			filenames.push(files[i].name);
		}
		fileinputpath.val(filenames.join(', '));
		fileinputpath.trigger('change');
	});
	///

	$('.modalLoop').on('shown.bs.modal', function () {
		$(this).find('[autofocus]').focus();
	});

	$('.modal').each(function () {
		$(this).on('show.bs.modal', function (l) {
			$('.modal-content-full').css({
				height: $(window).height(),
			});
		});
	});

	/// data toggle text

	$('[data-toggle-text]').each(function () {
		var $toggle = $(this);
		var originalText = $toggle.find('.text').text();
		var secondaryText = $toggle.data('toggle-text');
		var $target = $($toggle.data('target'));

		$target.on('show.bs.collapse hide.bs.collapse', function () {
			if ($toggle.find('.text').text() == originalText) {
				$toggle.find('.text').text(secondaryText);
			} else {
				$toggle.find('.text').text(originalText);
			}
		});
	});
 
	// Move to top button appand in web layout
	$('[data-collapse-text]').each(function () {
		var $toggle = $(this);
		var originalText = $toggle.find('.text').text();
		var secondaryText = $toggle.data('collapse-text');
		var $target = $($toggle.data('target'));
		var $dataIcon = $toggle.data('fa-icon');
		var $dataCollapseIcon = $toggle.data('collapse-icon');
		var $dataParentsId = $toggle.data('parents-id');

		$target.on('show.bs.collapse hide.bs.collapse', function () {
			console.log($dataIcon, $dataCollapseIcon);
			if ($toggle.find('.text').text() == originalText) {
				$toggle.find('.text').text(secondaryText);
				$toggle
					.find('.icons')
					.removeClass($dataCollapseIcon)
					.addClass($dataIcon);
				$($dataParentsId).addClass('active');
			} else {
				$toggle.find('.text').text(originalText);
				$toggle
					.find('.icons')
					.removeClass($dataIcon)
					.addClass($dataCollapseIcon);
				$($dataParentsId).removeClass('active');
			}
		});
	});

	//
	// Move to top button appand in web layout

	$('.form-datepicker').datepicker({
		showOtherMonths: true,
		selectOtherMonths: true,
		dateFormat: 'dd-mm-yy',
		minDate: 0,
		showAnim: 'fadeIn',
		changeMonth: true,
		changeYear: true,
		// todayBtn: true,
		autoclose: true,
		prevText: '<i class="fa fa-chevron-left"></i>',
		nextText: '<i class="fa fa-chevron-right"></i>',
		afterShow: function () {
			$('.ui-datepicker-prev')
				.remove()
				.append('<i class="fa fa-chevron-left icons"></i>');
			$('.ui-datepicker-next')
				.remove()
				.append('<i class="fa fa-chevron-right icons"></i>');
		},
	});

	$('.form-datepicker,.datepicker,.hasDatepicker').attr('autocomplete', 'off');

	///
	$('.check-in-date').datepicker({
		showOtherMonths: true,
		selectOtherMonths: true,
		dateFormat: 'dd-mm-yy',
		minDate: 0,
		showAnim: 'fadeIn',
		changeMonth: true,
		changeYear: true,
		// todayBtn: true,
		todayBtn: false,
		autoclose: true,
		prevText: '<i class="fa fa-chevron-left"></i>',
		nextText: '<i class="fa fa-chevron-right"></i>',
		// beforeShow: function (textbox, instance) {
		//     $('.detail-sidebar-inner').append($('#ui-datepicker-div'));
		// },
		afterShow: function () {
			$('.ui-datepicker-prev')
				.remove()
				.append('<i class="fa fa-chevron-left icons"></i>');
			$('.ui-datepicker-next')
				.remove()
				.append('<i class="fa fa-chevron-right icons"></i>');
		},
		onSelect: function (selectedDate) {
			dataChckout = $(this).data('check-out');

			$('#' + dataChckout).datepicker('option', 'minDate', selectedDate);

			if ($('#' + dataChckout).val() != null)
				setTimeout(function () {
					$('#' + dataChckout).datepicker('show');
				}, 100);
		},
	});

	$('.check-out-date').datepicker({
		showOtherMonths: true,
		selectOtherMonths: true,
		dateFormat: 'dd-mm-yy',
		showAnim: 'fadeIn',
		changeMonth: true,
		changeYear: true,
		todayBtn: false,
		autoclose: true,
		prevText: '<i class="fa fa-chevron-left"></i>',
		nextText: '<i class="fa fa-chevron-right"></i>',
		minDate: $('.check-in-date').val(),
		// beforeShowDay: function (dateText, inst) {
		// },
		// onSelect: function (dateText, inst) {
		// },
		// beforeShow: function (textbox, instance) {
		//     $('.detail-sidebar-inner').append($('#ui-datepicker-div'));
		// },
		afterShow: function () {
			$('.ui-datepicker-prev')
				.remove()
				.append('<i class="fa fa-chevron-left icons"></i>');
			$('.ui-datepicker-next')
				.remove()
				.append('<i class="fa fa-chevron-right icons"></i>');
		},
	});
	$('.has-spinner').each(function (e) {
		$(this).on('click', function (e) {
			var btnSpinner = $(this);
			$(btnSpinner).buttonLoader('start');
			setTimeout(function () {
				$(btnSpinner).buttonLoader('stop');
			}, 4000);
		});
	});

	// var windowsWidth = $(window).width();

	// if (windowsWidth <= 767) {
	// 	// console.log('mobile');
	// 	$('.btn-navbar-toggle').sideNav({
	// 		menuWidth: 290, // Default is 240
	// 		//edge: 'right', // Choose the horizontal origin
	// 		closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
	// 		draggable: true, // Choose whether you can drag to open on touch screens
	// 	});
	// 	$('.mobile-filter-menu').removeAttr('style');
	// } else {
	// 	// console.log('desktop');
	// }
});

$(window).on('load resize scroll', function () {
	$('.page-loader,.page-loader-wrapper').height($(window).height());

	// shows mobile menu
	if (
		$(window).scrollTop() >=
		$('.pages').offset().top + $('.pages').outerHeight() - window.innerHeight
	) {
		$('.search-advanced-mobile').find('.mobile-wrapper').hide();
		$('.move-top').addClass('bottom');
		console.log('You reached the end of the DIV');
	} else {
		console.log('You  div the DIV');
		$('.search-advanced-mobile').find('.mobile-wrapper').show();
		$('.move-top').removeClass('bottom');
	}

	//
	var windowsWidth = $(window).width(),
		windowsHeight = $(window).height(),
		navbarHight = $('.siteNavbar').outerHeight(),
		footerHight = $('.page-footer').outerHeight();

	if (windowsWidth <= 767) {
	} else {
		// console.log('desktop');
		$('.pages').css({
			minHeight: windowsHeight - (navbarHight + footerHight),
		});
	}
});
